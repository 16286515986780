<template>
  <div class="ta-center table-empty-state">
    <Icon :icon="getIcon()" class="table-empty-state__icon" />
    <div v-if="activeTabId === 'invoices'">
      <span class="d-b table-empty-state__label display-3 mb-m">{{ $t('documents.no-documents.invoices.title') }}</span>
      <span class="d-b mb-l">{{ $t('documents.no-documents.invoices.info') }}</span>
    </div>
    <div v-if="activeTabId === 'certificates'">
      <span class="d-b table-empty-state__label display-3 mb-m">{{ $t('documents.no-documents.certificates.title') }}</span>
    </div>
    <div v-if="activeTabId === 'other'">
      <span class="d-b table-empty-state__label display-3 mb-m">{{ $t('documents.no-documents.other.title') }}</span>
    </div>
    <div v-if="activeTabId === 'recent'">
      <span class="d-b table-empty-state__label display-3 mb-m">{{ $t('inquiries.no-inquiries.recent.title') }}</span>
      <span class="d-b mb-l">{{ $t('inquiries.no-inquirires.recent.info') }}</span>
      <IconButton :to="{name: 'New inquiry'}" icon="plus-circle" background="blue-dark-10">{{ $t('topbar.new-request') }}</IconButton>
    </div>
    <div v-if="activeTabId === 'reimbursed'">
      <span class="d-b table-empty-state__label display-3 mb-m">{{ $t('inquiries.no-inquiries.reimbursed.title') }}</span>
    </div>
    <div v-if="activeTabId === 'rejected'">
      <span class="d-b table-empty-state__label display-3 mb-m">{{ $t('inquiries.no-inquiries.rejected.title') }}</span>
    </div>
    <div v-if="activeTabId === 'pending'">
      <span class="d-b table-empty-state__label display-3 mb-m">{{ $t('inquiries.no-inquiries.pending.title') }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import IconButton from '@/01-atoms/Icon-button.vue'

class Props {
  activeTabId?: string
}

@Options({
  components: {
    IconButton,
    Icon
  }
})

export default class TableEmptyState extends Vue.with(Props) {
  getIcon () {
    if (this.activeTabId === 'invoices' || this.activeTabId === 'certificates' || this.activeTabId === 'other') {
      return 'file-text'
    }
    return 'upload'
  }
}
</script>
<style lang="scss" scoped>
.table-empty-state__icon {
  background: $blue-dark-10;
  border-radius: 50%;
  margin: 0 auto 15px;
  padding: 12px;
  height: 50px;
  width: 50px;
}

.table-empty-state {
  padding-top: 30px;
}
</style>

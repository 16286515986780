
import { Vue } from 'vue-class-component'

interface HeaderRowItem {
  title: string;
  responsive: boolean;
}

class Props {
  label?: string;
  items?: Array<HeaderRowItem>
}

export default class TableHeader extends Vue.with(Props) {

}

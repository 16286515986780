
import { Options, Vue } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import Button from '@/01-atoms/Button.vue'
import TableEmptyState from '@/02-molecules/Table-empty-state.vue'

class Props {
  entries?: Array<object>;
  isLoading?: boolean;
  activeTabId?: boolean;
}

@Options({
  name: 'Table',
  components: {
    TableEmptyState,
    Button,
    LoadingDots,
    Tag,
    IconButton,
    Icon
  },
  emits: ['load-older-entries']
})
export default class Table extends Vue.with(Props) {
  clickLoadOlder () {
    this.$emit('load-older-entries')
  }

  hasEntries (entries: any) {
    return entries[0]?.length >= 1 || entries[1]?.length >= 1 || entries[2]?.length >= 1 || entries[3]?.length >= 1 || entries[4]?.length >= 1
  }
}

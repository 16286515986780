<template>
  <tr>
    <th class="fs-12 tt-uppercase table-header">
        <span class="table-header__divider">
          <span class="table-header__divider__label">{{ $t(label) }}</span>
        </span>
    </th>
    <th class="fs-12 tt-uppercase table-header" :class="[item.responsive && 'xl-up']" v-for="item in items" :key="item.title">{{ $t(item.title) }}</th>
  </tr>
</template>
<script lang="ts">
import { Vue } from 'vue-class-component'

interface HeaderRowItem {
  title: string;
  responsive: boolean;
}

class Props {
  label?: string;
  items?: Array<HeaderRowItem>
}

export default class TableHeader extends Vue.with(Props) {

}
</script>
<style lang="scss" scoped>
.table-header {
  @include brandon-grotesque;
  padding: 15px 10px 15px 0;
  position: relative;

  &:first-child .table-header__divider::after {
    content: '';
    background-color: $blue-dark-10;
    display: inline;
    margin-left: 10px;
    position: relative;
    height: 2px;
    width: 100%;
  }
}

.table-header__divider {
  display: flex;
  align-items: center;
}

.table-header__divider__label {
  flex-shrink: 0;
}
</style>

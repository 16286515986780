<template>
  <table class="table">
    <slot></slot>
  </table>
  <LoadingDots class="table__loading-dots" v-if="isLoading" />
  <div v-else-if="hasEntries(entries)" class="ta-center">
      <LoadingDots class="table__loading-dots" v-if="isLoading" />
      <Button v-else @click="clickLoadOlder">{{ $t('global.load-more-button') }}</Button>
  </div>
  <TableEmptyState :activeTabId="activeTabId" v-else />
  <div style="display: none">
    {{ $t('table.date') }}
    {{ $t('table.status') }}
    {{ $t('table.action') }}
    {{ $t('table.uploadedBy') }}
    {{ $t('table.inquiry') }}
    {{ $t('table.treatment') }}
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import Button from '@/01-atoms/Button.vue'
import TableEmptyState from '@/02-molecules/Table-empty-state.vue'

class Props {
  entries?: Array<object>;
  isLoading?: boolean;
  activeTabId?: boolean;
}

@Options({
  name: 'Table',
  components: {
    TableEmptyState,
    Button,
    LoadingDots,
    Tag,
    IconButton,
    Icon
  },
  emits: ['load-older-entries']
})
export default class Table extends Vue.with(Props) {
  clickLoadOlder () {
    this.$emit('load-older-entries')
  }

  hasEntries (entries: any) {
    return entries[0]?.length >= 1 || entries[1]?.length >= 1 || entries[2]?.length >= 1 || entries[3]?.length >= 1 || entries[4]?.length >= 1
  }
}
</script>
<style lang="scss">
.table {
  width: 100%;

  td {
    vertical-align: top;
  }
}

.table-row {
  &:hover {
    cursor: pointer;
  }
}

.table__item-preview {
  display: flex;
  align-items: center;
  padding-bottom: 18px;
}

.table__item-title {
  color: $blue-dark;
  margin: 0;
}

.table__loading-dots {
  margin-top: 50px;
}
</style>


import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import IconButton from '@/01-atoms/Icon-button.vue'

class Props {
  activeTabId?: string
}

@Options({
  components: {
    IconButton,
    Icon
  }
})

export default class TableEmptyState extends Vue.with(Props) {
  getIcon () {
    if (this.activeTabId === 'invoices' || this.activeTabId === 'certificates' || this.activeTabId === 'other') {
      return 'file-text'
    }
    return 'upload'
  }
}
